import Lines from "../../../../src/content/work/fwu/lines";
import BrowserOnlyRender from "../../../../src/components/browserOnlyRender";
import LinedColumns, { Column } from "../../../../src/components/linedColumns";
import LinedBlock from "../../../../src/components/linedBlock";
import NoiseShader from "../../../../src/content/work/fwu/noiseShader";
import * as React from 'react';
export default {
  Lines,
  BrowserOnlyRender,
  LinedColumns,
  Column,
  LinedBlock,
  NoiseShader,
  React
};